import { fetchUtils } from 'react-admin';
import inMemoryJWT from './inMemoryJWT';
import { rollbar } from './rollbar';

export const httpClient = async (url: any, options: any) => {
  if (!options) {
    options = {};
  }
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const token = await inMemoryJWT.getToken('httpClient');

  options.headers.set('Authorization', `Bearer ${token}`);

  try {
    // Attempt to fetch the JSON response
    const response = await fetchUtils.fetchJson(url, options);
    return response;
  } catch (error: any) {
    // Check if the error status is 422 or 403
    if (error.status === 422 || error.status === 403) {
      // Log the error to Rollbar with contextual information
      rollbar.warning(`HTTP Error ${error.status} on ${url}`, {
        url,
        status: error.status,
        options,
        message: error.message,
        stack: error.stack,
      });
    }

    // Re-throw the error to allow further handling if necessary
    throw error;
  }
};
