import React from 'react';
import {
  BooleanInput,
  DateInput,
  SelectInput,
  TextInput,
  required,
  useRecordContext,
} from 'react-admin';
import { MedicationSearchInput } from './MedicationSearchInput';
import { MedicationDosageInput } from './MedicationDosageInput';
import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';

const periodUnits = [
  { id: 'd', name: 'Day' },
  { id: 'wk', name: 'Week' },
  { id: 'mo', name: 'Month' },
  { id: 'a', name: 'Year' },
];

export const MedicationsCreateButton = ({ variant = 'default' }) => {
  const record = useRecordContext();

  const transform = (data) => {
    return {
      ...data,
      source: 'vironix',
      category: 'outpatient',
      status: 'in-progress',
    };
  };

  return (
    <CreateInDialogButton
      title="Add new medication"
      resource={`patients/${record?.id}/medication-statements/`}
      transform={transform}
      variant={variant}
      label="Medication"
      notifyMessage="Medication added"
      saveAndAddAnother
    >
      <div className="flex flex-col gap-1">
        <MedicationSearchInput />
        <div className="flex flex-col">
          <p>Dosing</p>
          <MedicationDosageInput />
          <div className="flex flex-row gap-1 items-center">
            <TextInput
              source="dosage[0].dose_and_rate[0].rate.numerator.value"
              label="Amount"
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              validate={required()}
            />
            <TextInput
              source="dosage[0].dose_and_rate[0].rate.denominator.value"
              label="Frequency"
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              validate={required()}
            />
            <SelectInput
              source="dosage[0].timing.repeat.periodUnit"
              choices={periodUnits}
              label="Period Unit"
              validate={required()}
            />
          </div>
          <BooleanInput source="dosage[0].as_needed" label="As Needed" />
        </div>
        <div className="flex flex-col">
          <p>Dosage Period</p>
          <div className="flex flex-row gap-1">
            <DateInput
              source="start_date"
              label="Start Date"
              validate={required()}
            />
            <DateInput source="end_date" label="End Date" />
          </div>
        </div>
        <TextInput
          source="additional_info"
          label="Notes"
          multiline
          fullWidth
          minRows={3}
        />
      </div>
    </CreateInDialogButton>
  );
};
