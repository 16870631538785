import { ErrorBoundary, Provider } from '@rollbar/react';
import { get } from 'lodash';
import React from 'react';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import { QueryClient } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { AuditLogsList } from './pages/AuditLogs';
import { AuthProvider } from './utils/AuthProvider';
import DataProvider from './utils/DataProvider';
import { DeviceList, DeviceShow } from './pages/Devices';
import { Invitation } from './pages/Invitation';
import { Layout } from './modules/layout/components/Layout';
import { Login } from './pages/Login';
import { PatientQueue } from './pages/PatientQueue';

import { ReportList, ReportShow } from '@/pages/Reports';
import { SupportTicketList } from '@/pages/SupportTicket';
import { SupportTicketShow } from '@/pages/SupportTicket/SupportTicketShow';
import {
  OrganizationCreate,
  OrganizationShowEdit,
  TenantList,
} from '@/pages/Tenants';
import { OrganizationSetting } from '@/pages/Tenants/OrganizationSetting';
import { ConfirmOTP, Testing } from './pages/Testing';
import { theme } from './Theme';
import { UserList } from '@/pages/Users';
import { InternalUserList } from '@/pages/Users/InternalUserList';
import { UserShowEdit } from '@/pages/Users/UserShowEdit';
import { i18nProvider } from './utils/i18nProvider';
import { localStorageStore } from './utils/localStorageStore';
import { LocationList, LocationShow } from '@/pages/Locations';
import { PatientList } from './pages/Patients/PatientList';
import { PatientShowSimple } from './pages/Patients/PatientShowSimple';
import { PatientEdit } from './pages/Patients/PatientEdit';
import { PatientCreate } from './pages/Patients/PatientCreate';
import { PatientShow } from './pages/Patients/PatientShow';
import { ClaimItemList } from './pages/ClaimItem/ClaimItemList';
import { httpClient } from './utils/httpClient';
import { RootLoadingPage } from './modules/layout/components/RootLoading';
import { TaskList } from './pages/Tasks/TaskList';
import { Home } from './pages/Home/Home';
import { Account } from '@/pages/Account/Account';
import { DemoProvider } from './modules/demo/components/DemoProvider';
import { EnrollmentList } from './pages/Enrollment/EnrollmentList';
import { MonitoringProgress } from './pages/MonitoringProgress/MonitoringProgress';
import PassKeyLogin from './pages/Testing/PassKeyLogin';
import { rollbar } from './utils/rollbar';

const apiUrl = window.config.apiUrl;

export const dataProvider = DataProvider(apiUrl, httpClient);

const authProvider = AuthProvider();
const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 10 * 1000 } },
});

function AdminBase() {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      theme={theme}
      layout={Layout}
      queryClient={queryClient}
      loginPage={Login}
      store={localStorageStore()}
      requireAuth
      loading={RootLoadingPage}
      dashboard={Home}
    >
      {(permissions) => [
        get(permissions, 'patients') ? (
          <Resource
            name="patients"
            list={PatientList}
            show={PatientShowSimple}
            edit={PatientEdit}
            create={PatientCreate}
          />
        ) : null,
        get(permissions, 'patients.queue') ? (
          <Resource
            name="patient-queue"
            list={PatientQueue}
            show={PatientShow}
            options={{ label: 'Patient Queue' }}
          />
        ) : null,
        get(permissions, 'devices') ? (
          <Resource name="devices" list={DeviceList} show={DeviceShow} />
        ) : null,
        get(permissions, 'locations') ? (
          <Resource name="locations" list={LocationList} show={LocationShow} />
        ) : null,
        get(permissions, 'claim_items') ? (
          <Resource
            name="claim-items"
            list={ClaimItemList}
            options={{ label: 'Claims' }}
          />
        ) : null,
        get(permissions, 'monitoring_activity') ? (
          <Resource name="monitoring-activities" />
        ) : null,
        get(permissions, 'service_instances') ? (
          <Resource name="service-instances" />
        ) : null,
        get(permissions, 'tasks') ? (
          <Resource name="tasks" list={TaskList} />
        ) : null,
        get(permissions, 'task-activities') ? (
          <Resource name="task-activities" />
        ) : null,
        get(permissions, 'users.list') ? (
          <Resource
            name="users"
            list={get(permissions, 'users.list') && UserList}
            edit={UserShowEdit}
          />
        ) : null,
        get(permissions, 'support_tickets') ? (
          <Resource
            name="support-tickets"
            list={SupportTicketList}
            show={SupportTicketShow}
            options={{ label: 'Support Tickets' }}
          />
        ) : null,
        get(permissions, 'tenants.list') === 'all' ? (
          <Resource
            name="providers"
            list={TenantList}
            edit={OrganizationShowEdit}
            create={OrganizationCreate}
          />
        ) : null,
        get(permissions, 'reports.list') ? (
          <Resource name="reports" list={ReportList} show={ReportShow} />
        ) : null,
        get(permissions, 'audit_logs.list') ? (
          <Resource
            name="audit-logs"
            list={AuditLogsList}
            options={{ label: 'Audit Logs' }}
          />
        ) : null,

        <CustomRoutes>
          {get(permissions, 'role') === 'superadmin' &&
          window.config.env !== 'production' ? (
            <Route path="/testing" element={<Testing />} />
          ) : null}
          {['admin', 'superadmin'].includes(get(permissions, 'role')) ? (
            <Route path="/organization" element={<OrganizationSetting />} />
          ) : null}
          {get(permissions, 'internal_users.update') ? (
            <Route path="/internal-users/:id/*" element={<UserShowEdit />} />
          ) : null}
          {get(permissions, 'internal_users.list') ? (
            <Route path="/internal-users" element={<InternalUserList />} />
          ) : null}
          <Route path="/account" element={<Account />} />
          <Route path="/enrollment" element={<EnrollmentList />} />
          <Route path="/monitoring" element={<MonitoringProgress />} />
        </CustomRoutes>,
      ]}
    </Admin>
  );
}

function App() {
  return (
    <Provider instance={rollbar}>
      <ErrorBoundary>
        <DemoProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<AdminBase />} />
              <Route path="/otp" element={<ConfirmOTP />} />
              <Route path="/mobile/invite" element={<Invitation />} />
              {window.config.env !== 'production' ? (
                <Route path="/passkey" element={<PassKeyLogin />} />
              ) : null}
            </Routes>
          </BrowserRouter>
        </DemoProvider>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
