import { LuxonDateField } from '@/modules/ra-ui/components/LuxonDateField';
import { PhoneNumberInput } from '@/modules/ra-ui/components/PhoneNumberInput';
import {
  CardDescription,
  CardFooter,
  CardTitle,
} from '@/modules/ui/components/card';

import { Button } from '@/modules/ui/components/button';
import { Card, CardContent, CardHeader } from '@/modules/ui/components/card';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/modules/ui/components/tabs';
import { Stack } from '@mui/material';

import AppTitle from '@/modules/layout/components/app-title';
import { AssignToProviderButton } from '@/modules/ra-ui/components/AssignToProviderButton';
import PasswordResetButton from '@/modules/ra-ui/components/PasswordResetButton';
import { RoleRadioSelect } from '@/modules/ra-ui/components/RoleRadioSelect';
import { SaveButton } from '@/modules/ra-ui/components/save-button';
import { cn } from '@/modules/ui/utils/cn';
import { AuthLogPanel } from '@/modules/users/components/AuthLogPanel';
import { PatientsUnderCareTab } from '@/modules/users/components/PatientsUnderCareTab';
import TwoFAResetButton from '@/modules/users/components/TwoFAResetButton';
import { UserPatientEnrollment } from '@/modules/users/components/UserPatientEnrollment';
import { get, set, unset } from 'lodash';
import React from 'react';
import {
  BooleanInput,
  DeleteButton,
  EditBase,
  Form,
  ReferenceInput,
  SelectInput,
  TextInput,
  maxLength,
  minLength,
  required,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';
import { RolesTab } from '../../modules/users/components/Roles';

export function UserShowEdit() {
  const editTransform = (data, { previousData }) => {
    // if role is unchanged, remove it from the data
    if (previousData && previousData.role === data.role) {
      unset(data, 'role');
    }

    if (get(data, 'phone_number') === '+1') {
      set(data, 'phone_number', null);
    }
    return data;
  };

  return (
    <div className="m-4 mb-2">
      <AppTitle title="Edit user" />
      <EditBase redirect={null} transform={editTransform} resource="users">
        <Form>
          <UserShowEditBody />
        </Form>
      </EditBase>
    </div>
  );
}

const UserShowEditBody = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions();

  if (!record) {
    return null;
  }

  return (
    <div>
      <Tabs defaultValue="account" className=" pt-2">
        <TabsList
          className={cn('grid w-full grid-cols-3', {
            'grid-cols-5': get(record, 'is_internal'),
          })}
        >
          <TabsTrigger value="account">Account</TabsTrigger>
          {get(record, 'is_internal') ? (
            <TabsTrigger value="roles">Roles</TabsTrigger>
          ) : null}
          <TabsTrigger value="patients">Patients</TabsTrigger>
          {get(record, 'is_internal') ? (
            <TabsTrigger value="enrollment">Enrollment</TabsTrigger>
          ) : null}
          <TabsTrigger value="auth-log">Security</TabsTrigger>
        </TabsList>
        <TabsContent value="account">
          <Card>
            <CardHeader>
              <CardTitle>Account</CardTitle>
              <CardDescription>
                Make changes to the user account here. Click save when you're
                done.
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-2">
              <Stack>
                <Stack gap={1} direction="row">
                  <TextInput source="first_name" validate={[required()]} />
                  <TextInput source="middle_name" />
                  <TextInput source="last_name" validate={[required()]} />
                </Stack>
                <Stack gap={1} direction="row">
                  <TextInput
                    source="email"
                    type="email"
                    validate={[required()]}
                  />
                  <PhoneNumberInput
                    label="Phone Number"
                    source="phone_number"
                  />
                </Stack>
                {!!!record.is_internal && get(permissions, 'is_internal') ? (
                  <Stack gap={1} direction="row">
                    <ReferenceInput source="tenant_id" reference="providers">
                      <SelectInput
                        label="Provider"
                        optionText="name"
                        disabled
                        sx={{
                          minWidth: 400,
                        }}
                        source="name"
                        helperText="Use the `Set Provider` button to change the provider."
                      />
                    </ReferenceInput>
                  </Stack>
                ) : null}
                <Stack gap={1} direction="row">
                  <TextInput
                    source="npi_number"
                    label="NPI Number"
                    helperText="10-digit NPI Number"
                    validate={[
                      minLength(10, 'NPI number must be 10 digits'),
                      maxLength(10, 'NPI number must be 10 digits'),
                    ]}
                  />
                  <TextInput source="credentials" helperText="MD, RN, PhD" />
                  <BooleanInput source="is_provider" label="Is clinician" />
                </Stack>
                {!record.is_internal ? (
                  <RoleRadioSelect internal={false} />
                ) : null}

                <div className="flex flex-row justify-start w-fit space-x-4 bg-gray-100 p-4 mt-2 rounded-md">
                  <div className="flex-1">
                    <div className="text-sm font-semibold text-gray-700 whitespace-nowrap">
                      Welcome Email Last Sent
                    </div>
                    <LuxonDateField
                      source="welcome_email_last_sent"
                      showTime={true}
                      emptyText="Never"
                      className="text-lg font-medium text-gray-900"
                    />
                  </div>
                  <div className="flex-1">
                    <div className="text-sm font-semibold text-gray-700 whitespace-nowrap">
                      Password Reset Email Last Sent
                    </div>
                    <LuxonDateField
                      source="reset_pass_email_last_sent"
                      showTime={true}
                      emptyText="Never"
                      className="text-lg font-medium text-gray-900"
                    />
                  </div>
                  <div className="flex-1">
                    <div className="text-sm font-semibold text-gray-700   whitespace-nowrap">
                      Last Login
                    </div>
                    <LuxonDateField
                      source="last_login"
                      showTime={true}
                      emptyText="Never"
                      className="text-lg font-medium text-gray-900"
                    />
                  </div>
                </div>
              </Stack>
            </CardContent>
            <CardFooter className="flex flex-row justify-between">
              <Stack direction="row" alignItems="center" spacing={2}>
                <EnableUserButton source="is_active" />
                <PasswordResetButton />
                <TwoFAResetButton />
                {record.is_internal ? null : (
                  <AssignToProviderButton resource="users" />
                )}
              </Stack>
              <SaveButton variant="default" type="submit" />
            </CardFooter>
          </Card>
        </TabsContent>
        {get(record, 'is_internal') ? (
          <TabsContent value="roles">
            <RolesTab />
          </TabsContent>
        ) : null}

        <TabsContent value="patients">
          <PatientsUnderCareTab />
        </TabsContent>
        {get(record, 'is_internal') ? (
          <TabsContent value="enrollment">
            <UserPatientEnrollment />
          </TabsContent>
        ) : null}
        <TabsContent value="auth-log">
          <AuthLogPanel userId={record.id} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

const EnableUserButton = (props) => {
  const { source } = props;
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();

  const [update, { isLoading }] = useUpdate(
    'users',
    {
      id: record?.id,
      data: { [source]: record?.is_active ? false : true },
    },
    {
      onSuccess: (data) => {
        notify(record?.is_active ? 'User disabled' : 'User enabled');
        refresh();
      },
    },
  );

  if (!record) {
    return null;
  }

  const handleClick = () => update();

  return record.is_active ? (
    <Button
      disabled={isLoading}
      variant="outline"
      color="error"
      onClick={handleClick}
      type="button"
    >
      Disable User
    </Button>
  ) : (
    <Button
      disabled={isLoading}
      variant="outline"
      onClick={handleClick}
      type="button"
    >
      Enable User
    </Button>
  );
};

export const DeleteCareMemberButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();

  return (
    <DeleteButton
      resource={`patients/${record?.patient_id}/care-teams`}
      redirect={false}
      mutationMode="pessimistic"
      label=""
      confirmTitle="Remove patient from care of this user?"
      confirmContent="This will remove the patient from the care of this user."
      mutationOptions={{
        onSuccess: () => {
          refresh();
        },
      }}
      sx={{
        minWidth: 'unset',
        '& .MuiButton-startIcon': {
          mr: 0,
        },
      }}
    />
  );
};
