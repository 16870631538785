import React, { FC } from 'react';
import { ShowBase, usePermissions } from 'react-admin';

import { useAISummaries } from '@/modules/ai-summaries/components/useAISummaries';
import { PatientLayout } from '@/modules/patients/components/PatientLayout';
import { PatientStatusProvider } from '@/modules/patients/hooks/patient-status';
import { ScrollArea, ScrollBar } from '@/modules/ui/components/scroll-area';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/modules/ui/components/tabs';
import ChartLine from 'healthicons-react/dist/filled/ChartLine';
import ClinicalF from 'healthicons-react/dist/filled/ClinicalF';
import CommunityHealthworker from 'healthicons-react/dist/filled/CommunityHealthworker';
import HealthWorkerForm from 'healthicons-react/dist/filled/HealthWorkerForm';
import IExamMultipleChoice from 'healthicons-react/dist/filled/IExamMultipleChoice';
import INoteAction from 'healthicons-react/dist/filled/INoteAction';
import Symptom from 'healthicons-react/dist/filled/Symptom';
import { Link, useParams } from 'react-router-dom';
import { AISummariesSection } from '../../modules/ai-summaries/components/AISummaries';
import { MetricsSection } from '../../modules/metrics/components/Metrics';
import AssessmentsSection from '../../modules/patients/clinical-sections/components/Assessments';
import CarePlansSection from '../../modules/patients/clinical-sections/components/CarePlans';
import ClinicalSection from '../../modules/patients/clinical-sections/components/Clinical';
import EncountersSection from '../../modules/patients/clinical-sections/components/Encounters';
import MonitoringActivitiesSection from '../../modules/patients/clinical-sections/components/MonitoringActivities';
import ServicesSection from '../../modules/patients/clinical-sections/components/Services';

const PatientShowTabs = () => {
  const params = useParams();
  const { permissions } = usePermissions();
  const section = params['*'].split('/')[0] || 'metrics';
  const { data: llmEnabled } = useAISummaries();
  const showLLM = llmEnabled && permissions.is_internal;

  return (
    <Tabs value={section}>
      <ScrollArea>
        <TabsList className="flex h-12  h-fit mb-2">
          <TabsTrigger asChild value="metrics" className="grow text-sm">
            <Link to="metrics">
              <ChartLine className="opacity-70 mr-1" />
              Metrics
            </Link>
          </TabsTrigger>
          <TabsTrigger asChild value="assessments" className="grow text-sm">
            <Link to="assessments">
              <IExamMultipleChoice className="opacity-70 mr-1" />
              Assessments
            </Link>
          </TabsTrigger>
          <TabsTrigger asChild value="care_plans" className="grow text-sm">
            <Link to="care_plans">
              <ClinicalF className="opacity-70 mr-1" />
              Care Plans
            </Link>
          </TabsTrigger>
          <TabsTrigger asChild value="clinical" className="grow text-sm">
            <Link to="clinical/observations">
              <Symptom className="opacity-70 mr-1" />
              Clinical
            </Link>
          </TabsTrigger>

          <TabsTrigger asChild value="charting" className="grow text-sm">
            <Link to="charting">
              <INoteAction className="opacity-70 mr-1" />
              Monitoring
            </Link>
          </TabsTrigger>
          <TabsTrigger asChild value="care_services" className="grow text-sm">
            <Link to="care_services">
              <CommunityHealthworker className="opacity-70 mr-1" />
              Care Services
            </Link>
          </TabsTrigger>
          {showLLM && (
            <TabsTrigger asChild value="ai_summaries" className="grow text-sm">
              <Link to="ai_summaries">
                <HealthWorkerForm className="opacity-70 mr-1" />
                AI Summary
              </Link>
            </TabsTrigger>
          )}
        </TabsList>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
      <TabsContent className="px-1" value="metrics">
        <MetricsSection />
      </TabsContent>
      <TabsContent className="px-1" value="assessments">
        <AssessmentsSection />
      </TabsContent>
      <TabsContent className="px-1" value="care_plans">
        <CarePlansSection />
      </TabsContent>
      <TabsContent className="px-1" value="clinical">
        <ClinicalSection />
      </TabsContent>

      <TabsContent className="px-1" value="encounters">
        <EncountersSection />
      </TabsContent>
      <TabsContent className="px-1" value="charting">
        <MonitoringActivitiesSection />
      </TabsContent>
      <TabsContent className="px-1" value="care_services">
        <ServicesSection />
      </TabsContent>
      {showLLM && (
        <TabsContent className="px-1" value="ai_summaries">
          <AISummariesSection />
        </TabsContent>
      )}
    </Tabs>
  );
};

export const PatientShow: FC = (props) => {
  return (
    <ShowBase
      {...props}
      resource="patients"
      queryOptions={{
        meta: { expand: ['profile', 'measure_reports', 'observations'] },
      }}
    >
      <PatientStatusProvider>
        <PatientLayout>
          <PatientShowTabs />
        </PatientLayout>
      </PatientStatusProvider>
    </ShowBase>
  );
};
