import RangeField from '@/modules/ra-ui/components/RangeField';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import { get } from 'lodash';
import { Pill } from 'lucide-react';
import React from 'react';
import {
  Datagrid,
  FunctionField,
  Pagination,
  TextField,
  useGetList,
  useRecordContext,
  ListContextProvider,
} from 'react-admin';
import { MedicationDeleteButton } from './MedicationDeleteButton';
import { MedicationEditButton } from './MedicationEditButton';

export const MedicationsList = () => {
  const record = useRecordContext();

  const sort = { field: null, order: null };
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(20);
  const [filterValues, setFilters] = React.useState({});

  const { data, total, isLoading } = useGetList(
    `patients/${record?.id}/medication-statements`,
    {
      pagination: { page, perPage },
      sort,
      filter: filterValues,
    },
    {
      enabled: !!record,
    },
  );

  if (!record) {
    return null;
  }

  return (
    <div className="w-full overflow-x-auto">
      <ListContextProvider
        // @ts-ignore
        value={{ filterValues, setFilters, displayedFilters: {} }}
      >
        <Datagrid
          data={data}
          total={total}
          isLoading={isLoading}
          sort={sort}
          bulkActionButtons={false}
          empty={
            <EmptyDatagrid
              resourceName="Medications"
              icon={<Pill />}
              textFormatString="No medications found"
            />
          }
        >
          <TextField source="medication.name" label="Medication" />
          <FunctionField
            render={(record) =>
              get(record, 'dosage[0].dose_and_rate[0].dose_quantity.value', '')
            }
            label="Dosage"
          />
          <FunctionField
            render={(record) => {
              const frequency = get(
                record,
                'dosage[0].timing.repeat.frequency',
              );
              const periodUnit = get(
                record,
                'dosage[0].timing.repeat.periodUnit',
              );
              if (frequency && periodUnit) {
                return `${frequency} x ${periodUnit}`;
              } else {
                return '';
              }
            }}
            label="Frequency"
          />
          <BadgeField source="status" />
          <RangeField
            source_start="start_date"
            source_end="end_date"
            sortBy="start_date"
            label="Timestamp"
          />
          <BadgeField source="source" />
          <FunctionField
            render={(record) =>
              record.status === 'active' &&
              record.source === 'vironix' && <MedicationEditButton label="" />
            }
          />
          <FunctionField
            render={(record) =>
              record.status === 'active' &&
              record.source === 'vironix' && <MedicationDeleteButton />
            }
          />
        </Datagrid>
        {total > 10 ? (
          <Pagination
            total={total}
            perPage={perPage}
            page={page}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        ) : null}
      </ListContextProvider>
    </div>
  );
};
