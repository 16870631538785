import { Avatar, AvatarFallback } from '@/modules/ui/components/avatar';
import { Button } from '@/modules/ui/components/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/modules/ui/components/dropdown-menu';
import { Label } from '@/modules/ui/components/label';
import { Switch } from '@/modules/ui/components/switch';
import { DropdownMenuArrow } from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import inflection from 'inflection';
import { get } from 'lodash';
import { Settings } from 'lucide-react';
import React, { useCallback } from 'react';
import {
  useGetIdentity,
  useLogout,
  usePermissions,
  useStore,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { useSidebarState } from '../sidebar/hooks/useSidebarState';

function getInitials(string, num = 1) {
  if (!string) return '?';
  var names = string.split(' '),
    initials = names[0].substring(0, 1).toUpperCase();

  if (num > 1 && names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

function SandboxToggle() {
  const [value, setValue] = useStore('preferences.hideSandboxPatients', true);

  const handleChange = (checked) => {
    setValue(checked);
  };

  return (
    <div className="flex items-center space-x-2">
      <Switch
        id="sandbox-patients"
        checked={value}
        onCheckedChange={handleChange}
        className="h-6"
      />
      <Label htmlFor="sandbox-patients">Hide Sandbox</Label>
    </div>
  );
}

export function UserNav() {
  const { identity, isLoading } = useGetIdentity();
  const [open] = useSidebarState();
  const { permissions } = usePermissions();

  const logout = useLogout();

  const handleClick = useCallback(
    () => logout(null, undefined, false),
    [logout],
  );

  if (isLoading) return null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className={clsx(
            'relative h-12 w-full justify-start px-4 space-x-4',
            !open && 'px-1',
          )}
        >
          <Avatar className={clsx('h-8 w-8 m-0', !open && 'm-auto')}>
            {/* <AvatarImage src="/avatars/01.png" alt="@shadcn" /> */}
            <AvatarFallback className="bg-slate-300">
              {identity && getInitials(identity.fullName)}
            </AvatarFallback>
          </Avatar>
          {open && <p>{get(identity, 'fullName')}</p>}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuArrow />
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">
              {get(identity, 'fullName')}
            </p>
            <p className="text-xs leading-none text-muted-foreground">
              {inflection.humanize(get(identity, 'role', ''))}
            </p>
            <p className="text-xs leading-none text-muted-foreground">
              {get(identity, 'email')}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuItem asChild>
          <Link key="account" to="/account">
            <Settings className="mr-2 h-4 w-4 " />
            Account
          </Link>
        </DropdownMenuItem>
        {/* TODO: Uncomment this when we have a way to manage organization settings */}
        {/* {['admin'].includes(get(permissions, 'role')) && organization ? (
          <DropdownMenuItem asChild>
            <Link key="Organization" to="/organization">
              <SlidersHorizontal className="mr-2 h-4 w-4 " />
              Organization
            </Link>
          </DropdownMenuItem>
        ) : null} */}
        <DropdownMenuSeparator />

        {get(permissions, 'is_internal') === true && (
          <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
            <SandboxToggle />
          </DropdownMenuItem>
        )}
        <DropdownMenuItem onClick={handleClick}>Log out</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
