import CreateInDialogButton from '@/modules/ra-ui/components/create-dialog-button';
import EditInDialogButton from '@/modules/ra-ui/components/edit-dialog-button';
import EmptyDatagrid from '@/modules/ra-ui/components/empty-datagrid-section';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@/modules/ui/components/alert';
import { Stack } from '@mui/material';
import { get } from 'lodash';
import { AlertTriangle } from 'lucide-react';
import { DateTime } from 'luxon';
import React from 'react';
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  FormDataConsumer,
  FunctionField,
  Pagination,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SingleFieldList,
  TextField,
  required,
  useGetList,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { ICD10Chip } from '../../icd10/components/ICD10Chip';
import { ICD10ArrayPatientInput } from '../../icd10/components/ICD10Input';
import { serviceInstanceTypes } from '../constants/serviceInstanceTypes';
import { serviceInstanceTypesChoices } from '../constants/serviceInstanceTypesChoices';

const filterProviderName = (searchText) => ({
  q: { field: 'full_name', value: searchText },
});

export const serviceInstanceCreateInputs = [
  // <BooleanInput
  //   helperText="This service will be tracked and added to the patients bill."
  //   source="is_tracked_for_insurance"
  //   label="Track for Insurance"
  // />,
];

export const ServiceInstanceCreateDialog = () => {
  const record = useRecordContext();

  const transform = (data) => {
    return { ...data, patient_id: record.id };
  };

  return (
    <CreateInDialogButton
      title="Create care program"
      resource="service-instances"
      transform={transform}
      variant="default"
      fullWidth
      label="Care Program"
      notifyMessage="Care program created"
      enableLogAction
      refreshOnSuccess
      logActionLabel={(data) => {
        const careProgramType = serviceInstanceTypes[data.type];
        return `Care program "${careProgramType}" created for patient`;
      }}
      initialData={{
        practitioner_id: record?.billing_provider_id,
        start: DateTime.fromISO(record?.user?.registered_on, {
          zone: 'utc',
        }).toISODate(),
      }}
    >
      <Stack direction="column" gap={0}>
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) => {
            if (
              (formData.type === 'ccm' ||
                formData.type === 'pcm' ||
                formData.type === 'cccm') &&
              get(
                record,
                'patient.profile.chronic_kidney_disease.in_dialysis',
              ) === true
            ) {
              return (
                <Alert variant="warning" className="mb-2">
                  <AlertTriangle className="h-4 w-4" />
                  <AlertTitle>
                    Dialysis patient may conflict with CCM / PCM
                  </AlertTitle>
                  <AlertDescription>
                    Neither CCM nor PCM can be billed at the same time as
                    End-Stage Renal Disease services codes (CPT codes
                    90951-90970). <br /> Neither RPM nor RTM should be billed
                    when there is a more specific monitoring service being
                    provided. <br /> We highly recommend consulting with your
                    biller.
                  </AlertDescription>
                </Alert>
              );
            }
          }}
        </FormDataConsumer>
        <DateInput source="start" validate={required()} helperText={false} />
        <SelectInput
          label="Service Type"
          source="type"
          validate={required()}
          choices={serviceInstanceTypesChoices.filter(
            (c) => c.enabled !== false,
          )}
          helperText={false}
        />
        <ICD10ArrayPatientInput patientId={record.id} />
        <ReferenceInput
          source="practitioner_id"
          reference="users"
          filter={{
            'role[ne]': 'patient',
            'npi_number[ne]': null,
            'is_provider[eq]': true,
            'tenant_id[eq]': record?.user?.tenant_id,
          }}
        >
          <AutocompleteInput
            label="Billing Provider"
            optionText="full_name"
            parse={(v) => (v === '' ? null : v)}
            helperText={false}
            filterToQuery={filterProviderName}
          />
        </ReferenceInput>
        <BooleanInput
          source="is_labor_provided"
          label="Vironix Labor Provided"
          defaultValue={true}
          helperText="Keep this on if Vironix will provide the labor for this service."
        />
      </Stack>
    </CreateInDialogButton>
  );
};

export const ServiceInstanceEditDialog = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const { permissions } = usePermissions();
  const transform = (data) => {
    return data;
  };

  if (!record) {
    return null;
  }

  return (
    <EditInDialogButton
      title="Edit care program"
      resource="service-instances"
      transform={transform}
      refreshOnSuccess={true}
      deleteButton={
        <DeleteButton
          resource="service-instances"
          redirect={false}
          mutationOptions={{
            onSuccess: () => {
              notify('Care program deleted.');
              refresh();
            },
          }}
          confirmTitle="Delete Care Program?"
        />
      }
    >
      <Stack direction="column" gap={0}>
        <DateInput source="start" validate={required()} />
        <SelectInput
          label="Service Type"
          source="type"
          required
          choices={serviceInstanceTypesChoices.filter(
            (c) => c.enabled !== false,
          )}
        />
        <ICD10ArrayPatientInput patientId={record.patient_id} />
        <ReferenceInput
          source="practitioner_id"
          reference="users"
          filter={{
            'role[ne]': 'patient',
            'is_provider[eq]': true,
            'npi_number[ne]': null,
          }}
        >
          <AutocompleteInput
            label="Billing Provider"
            optionText="full_name"
            parse={(v) => (v === '' ? null : v)}
            filterToQuery={filterProviderName}
          />
        </ReferenceInput>
        <BooleanInput
          source="is_labor_provided"
          label="Vironix Labor Provided"
          disabled={get(permissions, 'is_internal') !== true}
        />
        <BooleanInput source="is_active" label="Active" />
      </Stack>
    </EditInDialogButton>
  );
};

export function ServiceInstanceDataGrid() {
  const record = useRecordContext();

  const [sort, setSort] = React.useState({ field: null, order: null });
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);

  const { data, total, isLoading } = useGetList('service-instances', {
    pagination: { page, perPage },
    filter: { 'patient_id[eq]': record?.id },
    sort,
  });
  if (!record) {
    return null;
  }

  return (
    <>
      <Datagrid
        data={data}
        total={total}
        isLoading={isLoading}
        sort={sort}
        bulkActionButtons={false}
        setSort={setSort}
        empty={
          <EmptyDatagrid
            resourceName="Care Programs"
            textFormatString="No Care Programs found for patient"
            description="Care programs are used to track progress and bill for services. If you intend to bill for services, please add the appropriate virtual care program."
          />
        }
      >
        <FunctionField
          label="Care program type"
          render={(record) =>
            `${get(serviceInstanceTypes, record.type, record.type)}`
          }
        />{' '}
        <DateField source="start" />
        <ReferenceArrayField
          label="Diagnosis Codes"
          reference="icd10"
          source="icd10_ids"
        >
          <SingleFieldList linkType={false}>
            <ICD10Chip />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceField source="practitioner_id" reference="users">
          <TextField source="full_name" />
        </ReferenceField>
        <BooleanField source="is_active" label="Active" />
        <BooleanField
          source="is_labor_provided"
          label="Vironix Labor Provided"
        />
        <FunctionField
          render={(record) => record.is_active && <ServiceInstanceEditDialog />}
        />
      </Datagrid>
      {total > 10 && (
        <Pagination
          total={total}
          perPage={perPage}
          page={page}
          setPage={setPage}
          setPerPage={setPerPage}
        />
      )}
    </>
  );
}
