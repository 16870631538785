import React, { useEffect } from 'react';
import { SelectInput, required, useDataProvider } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';

export const MedicationDosageInput = () => {
  const { setValue } = useFormContext();
  const watchMedicationId = useWatch({
    name: 'medication',
  });

  useEffect(() => {
    // Clear the dosage field if the medication changes
    if (watchMedicationId === null) {
      setValue('dosage[0].dose_and_rate[0].dose_quantity.value', '');
    }
  }, [watchMedicationId, setValue]);

  const dataProvider = useDataProvider();

  const { data: medicationDosages, isLoading } = useQuery(
    ['medicationDosages', watchMedicationId?.rxcui],
    () =>
      dataProvider.getCustom(`medications/${watchMedicationId?.rxcui}`, {
        meta: {},
      }),
    {
      enabled: !!watchMedicationId,
    },
  );

  return (
    <SelectInput
      fullWidth
      source="dosage[0].dose_and_rate[0].dose_quantity.value"
      choices={
        medicationDosages
          ? medicationDosages.data.dosage.map((dosage) => ({
              id: dosage,
              name: dosage,
            }))
          : []
      }
      label="Dosage"
      validate={required()}
      disabled={!watchMedicationId}
      isLoading={isLoading}
      helperText={false}
    />
  );
};
