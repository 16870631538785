import ProviderField from '@/modules/ra-ui/components/ProviderField';
import AppTitle from '@/modules/layout/components/app-title';
import { DataTableFilterSingle } from '@/modules/data-table/components/data-table-filter-single';
import DataTableProviderFilter from '@/modules/data-table/components/data-table-provider-filter';
import { DataTableToolbar } from '@/modules/data-table/components/data-table-toolbar';
import DataTableDateRangeFilter from '@/modules/data-table/components/date-table-date-range-filter';
import BadgeField from '@/modules/ra-ui/components/badge-field';
import React, { FC } from 'react';
import {
  DatagridConfigurable,
  DateField,
  List,
  ReferenceField,
  TextField,
  WrapperField,
} from 'react-admin';

const Toolbar = () => (
  <DataTableToolbar showViewOptions={false}>
    <DataTableProviderFilter column="tenant_id[eq]" title="Provider" />
    <DataTableDateRangeFilter
      column="range_start"
      title="Date range"
      dateOnly={true}
    />
    <DataTableFilterSingle
      column="type[eq]"
      title="Report Type"
      options={[
        { label: 'Monitoring by Customer', value: 'monitoring_report' },
        {
          label: 'Monitoring by Clinician',
          value: 'clinician_monitoring_report',
        },
        {
          label: 'Care plans by Clinician',
          value: 'care_plan_clinician_report',
        },
        { label: 'Care plans by Customer', value: 'care_plan_report' },
      ]}
    />
  </DataTableToolbar>
);

export const ReportList: FC = (props) => {
  return (
    <>
      <AppTitle title="Reports" />
      <List
        {...props}
        actions={<Toolbar />}
        storeKey={false}
        sort={{
          field: null,
          order: null,
        }}
        empty={
          <>
            <div className="max-full items-center mx-auto d-flex justify-content-center align-items-center">
              <div className="flex flex-col items-center justify-center h-full gap-5">
                <p className="font-bold text-xl">No reports found</p>
                <p className="text-gray-500 text-md">
                  Reports are automatically generated once services begin.
                </p>
              </div>
            </div>
          </>
        }
      >
        <DatagridConfigurable
          rowClick="show"
          omit={['end_date_of_service']}
          bulkActionButtons={false}
        >
          <TextField source="name" />
          <DateField source="report_date" />
          <WrapperField label="Range" sortBy="range_start">
            <DateField source="range_start" />
            {' - '}
            <DateField source="range_end" />
          </WrapperField>
          <BadgeField source="type" />
          <ProviderField
            label="Provider"
            source="tenant_id"
            sortBy="tenant.name"
          />
          <ReferenceField
            label="Patient"
            source="patient_id"
            reference="users"
            link={(record) => `/patient-queue/${record.id}/show#services`}
            sortable={false}
            emptyText="N/A"
          >
            <TextField source="full_name" />
          </ReferenceField>
        </DatagridConfigurable>
      </List>
    </>
  );
};
