import decodeJwt from 'jwt-decode';
import {
  HttpError,
  AuthProvider as RaAuthProvider,
  UserIdentity,
  fetchUtils,
} from 'ra-core';
import inMemoryJWT from './inMemoryJWT';
import { OMIT_SUFFIX } from './localStorageStore';

const apiUrl = window.config.apiUrl;

export interface HttpErrorType {
  status: number;
  message: string;
  json?: any;
}

class AuthClient {
  HTTPClient = fetchUtils.fetchJson;

  public async HandleAuthLogin(params: {
    username: string;
    password: string;
    credential?: any;
    totp?: string;
    mfaToken?: string;
    mfaType?: string;
  }) {
    const { username, password, credential, mfaToken, mfaType, totp} = params;

    if (mfaType === 'webauthn') {
      return this.HandleWebAuthnMFA({ credential, mfaToken });
    }
    if (mfaType === 'totp') {
      return this.HandleTOTPAuth({ totp, mfaToken });
    }

    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('client_id', 'webapp');

    const request = new Request(apiUrl + '/auth/login', {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });

    inMemoryJWT.setRefreshTokenEndpoint(apiUrl + '/auth/refresh');

    return fetch(request)
      .then((response) =>
        response.text().then((text) => ({
          status: response.status,
          statusText: response.statusText,
          headers: response.headers,
          body: text,
        })),
      )
      .then(({ status, statusText, body }) => {
        const json = JSON.parse(body);

        if (status < 200 || status >= 300) {
          return Promise.reject(
            new HttpError((json && json.message) || statusText, status, json),
          );
        }
        return json;
      })
      .then(({ access_token, token_expiry, token_type, preferred_mfa_method, available_mfa_methods }) => {
        if (token_type === 'mfa_challenge') {
          return Promise.reject({ error: {
            error: 'mfa_challenge',
            body: {
              mfa_token: access_token,
              preferred_mfa_method: preferred_mfa_method,
              available_mfa_methods:available_mfa_methods,
            },
          } });
        }

        return this.HandleValidAccessToken(access_token, token_expiry);
      })
      .catch((e) => {
        if (e.error === 'patient') {
          return Promise.resolve({ redirectTo: '/login?page=patient-success' });
        } else {
          return Promise.reject(e);
        }
      });
  }

  private async HandleTOTPAuth({totp, mfaToken}) {

    const request = new Request(apiUrl + '/auth/totp/authenticate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${mfaToken}`,
      },
      credentials: 'include',
      body: JSON.stringify({
        code: totp,
      }),
    });

    const response = await fetch(request);
    if (!response.ok) {
      const errorData = await response.json();
      throw new HttpError(errorData.message, response.status);
    }
    
    const data = await response.json();
    return this.HandleValidAccessToken(data.access_token, data.token_expiry);
  }


  private async HandleValidAccessToken(access_token: string, token_expiry: string) {
    const decodedToken: any = decodeJwt(access_token);

    if (decodedToken.role === 'patient') {
      return Promise.reject({ error: 'patient' });
    }

    if (!['patient'].includes(decodedToken.role)) {
      inMemoryJWT.setToken(access_token, token_expiry);

      await this.RefreshPermissions();
      const identity = await this.HandleGetIdentity();
      return Promise.resolve(identity);
    }

    return Promise.reject(new Error('Invalid role'));
  }

  public async HandleResetPassword(params: { email: string }) {
    const { email } = params;

    const request = new Request(apiUrl + '/auth/reset-password', {
      method: 'POST',
      body: JSON.stringify({ email: email }),
      headers: { 'content-type': 'application/json' },
    });

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(() => {
        return Promise.resolve();
      });
  }

  public async HandleSetPassword(params: {
    new_password: string;
    new_password_2: string;
    code: string;
  }) {
    const { new_password, new_password_2, code } = params;

    const request = new Request(apiUrl + '/auth/otp-set-password', {
      method: 'POST',
      body: JSON.stringify({ new_password_2, new_password, token: code }),
      headers: { 'content-type': 'application/json' },
    });

    inMemoryJWT.setRefreshTokenEndpoint(apiUrl + '/auth/refresh');

    return this.HTTPClient(request)
      .then(({ json }) => {
        return json;
      })
      .then(({ access_token, token_expiry }) => {
        const decodedToken: any = decodeJwt(access_token);

        if (!['patient'].includes(decodedToken.role)) {
          inMemoryJWT.setToken(access_token, token_expiry);
          return decodedToken;
        } else {
          return Promise.reject(new Error('Role is patient.'));
        }
      })
      .then(() => {
        return this.RefreshPermissions();
      })
      .then(() => {
        return this.HandleGetIdentity();
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  public async HandleAuthLogout() {
    const request = new Request(apiUrl + '/auth/logout', {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'include',
    });
  
    try {
      await fetch(request);
    } finally {
      // Always perform client-side logout actions
      inMemoryJWT.eraseToken();
      localStorage.removeItem('permissions');
    }
  
    return Promise.resolve();
  }

  public async HandleAuthError(errorHttp: HttpErrorType) {
    const status = errorHttp?.status;
    const message = errorHttp?.message;
    if (status === 401 && message?.toLowerCase().includes('signature has expired')) {
      try {
        console.log('401 error, refreshing token - auth check');
        await inMemoryJWT.getRefreshedToken();
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    } else if (status === 401) {
      console.log('401 error, refreshing token - auth check - rejected');
      return Promise.reject();
    }
    return Promise.resolve();
  }

  public async HandleAuthCheck(): Promise<void> {

    try {
      const token = await inMemoryJWT.getToken('auth_check');
      if (token) {
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject();
    }
  }

  private async RefreshPermissionsMethod(token: string) {
    const request = new Request(apiUrl + '/permissions', {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((permissions) => {
        localStorage.setItem('permissions', JSON.stringify(permissions));
        return permissions;
      });
  }

  public async RefreshPermissions() {
    const token = await inMemoryJWT.getToken('permissions');

    if (token) {
      return this.RefreshPermissionsMethod(token);
    } else {
      return Promise.reject();
    }
  }

  public async HandleGetPermissions() {
    if (localStorage.getItem('permissions')) {
      const permissions = JSON.parse(localStorage.getItem('permissions'));
      return permissions;
    } else {
      return this.RefreshPermissions();
    }
  }

  public async HandleGetIdentity(): Promise<UserIdentity> {
    try {
      const token = await inMemoryJWT.getToken('identity');
      const decodedToken: any = decodeJwt(token);

      const request = new Request(
        apiUrl + `/users/${decodedToken.sub}/identity`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return fetch(request)
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          if (data.settings) {
            Object.keys(data.settings).forEach((key) => {
              // check that key doesn't have suffix. if it does then dont save it.
              if (OMIT_SUFFIX.some((suffix) => key.endsWith(suffix))) {
                return;
              }

              localStorage.setItem(key, data.settings[key]);
            });
          }

          const outData = {
            id: decodedToken.sub,
            tenantId: decodedToken.tenant_id,
            role: decodedToken.role,
            fullName: data.full_name,
            email: data.email,
            mfa_enabled: data.mfa_enabled,
            is_internal: data.is_internal,
            preferred_mfa_method: data.preferred_mfa_method,
          };

          return outData;
        });
    } catch {
      return null as any;
    }
  }
  
  public async HandleWebAuthnMFA({credential, mfaToken}) {
    // Implementation for WebAuthn MFA handling
    const request = new Request(apiUrl + '/auth/webauthn/authenticate/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${mfaToken}`,
      },
      credentials: 'include',
      body: JSON.stringify(
        credential
      )
    });

    const response = await fetch(request);
    if (!response.ok) {
      const errorData = await response.json();
      throw new HttpError(errorData.message, response.status);
    }
    
    const data = await response.json();
    return this.HandleValidAccessToken(data.access_token, data.token_expiry);
  }


  public async HandleStartWebAuthnMFA({mfaToken}): Promise<void> {
    // Implementation for WebAuthn MFA handling

    const request = new Request(apiUrl + '/auth/webauthn/authenticate', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${mfaToken}`,
      },
      credentials: 'include',
    });

    try {
      const response = await fetch(request);
      if (!response.ok) {
        const errorData = await response.json();
        throw new HttpError(errorData.message, response.status);
      }

      return response.json();
    } catch (error) {
      throw error;
    }
  }


}

export function AuthProvider(): RaAuthProvider {
  const auth = new AuthClient();

  const provider: RaAuthProvider = {
    // React Admin Interface
    login: (params) => auth.HandleAuthLogin(params),
    logout: () => auth.HandleAuthLogout(),
    checkAuth: () => auth.HandleAuthCheck(),
    checkError: (error) => auth.HandleAuthError(error),
    getPermissions: () => auth.HandleGetPermissions(),
    getIdentity: () => auth.HandleGetIdentity(),
    // Custom Functions
    resetPassword: (params) => auth.HandleResetPassword(params),
    setPassword: (params) => auth.HandleSetPassword(params),
    webAuthnMFAStart : (params) => auth.HandleStartWebAuthnMFA(params),
  };
  return provider;
}

export function retrieveStatusTxt(status: number): 'ok' | 'unauthenticated' {
  // Make sure any successful status is OK.
  if (status >= 200 && status < 300) {
    return 'ok';
  }
  switch (status) {
    case 401: // 'unauthenticated'
    case 403: // 'permission-denied'
      return 'unauthenticated';

    case 0: // 'internal'
    case 400: // 'invalid-argument'
    case 404: // 'not-found'
    case 409: // 'aborted'
    case 429: // 'resource-exhausted'
    case 499: // 'cancelled'
    case 500: // 'internal'
    case 501: // 'unimplemented'
    case 503: // 'unavailable'
    case 504: // 'deadline-exceeded'
    default:
      // ignore
      return 'ok';
  }
}
