import React, { FC } from 'react';
import { ShowBase } from 'react-admin';

import { MedicationsCreateButton } from '@/modules/medications/components/MedicationsCreateButton';
import { MedicationsList } from '@/modules/medications/components/MedicationsList';
import { CareTeamCreateButton } from '@/modules/patients/care-team/components/CareTeamCreateButton';
import CareTeamList from '@/modules/patients/care-team/components/CareTeamList';
import {
  ConditionCreate,
  ConditionsDataGrid,
} from '@/modules/patients/clinical-sections/components/Conditions';
import { ConsentForm } from '@/modules/patients/components/ConsentForm';
import { EnrollmentInfo } from '@/modules/enrollment/components/EnrollmentInfo';
import { PatientLayout } from '@/modules/patients/components/PatientLayout';
import { PatientProfileSection } from '@/modules/patients/components/PatientProfileSection';
import {
  ServiceInstanceCreateDialog,
  ServiceInstanceDataGrid,
} from '@/modules/patients/components/ServiceInstanceCreate';
import {
  PatientStatusProvider,
  usePatientStatus,
} from '@/modules/patients/hooks/patient-status';
import { PatientMobileAppSection } from '@/modules/patients/mobile-app/components/PatientMobileAppSection';
import { PatientContactCreateButton } from '@/modules/patients/patient-contacts/components/ContactsCreateEditButton';
import { PatientContactsGrid } from '@/modules/patients/patient-contacts/components/PatientContactsGrid';
import { useRecordContext } from 'react-admin';
import { DevicesDataGrid } from '@/modules/patients/components/DevicesDataGrid';
import { DevicePatientActivities } from '@/modules/patients/components/DevicePatientActivities';

const PatientShowSimpleView = () => {
  const record = useRecordContext();
  const patientStatus = usePatientStatus();
  const isNotEnrolled = ['pending_enrollment', 'declined'].includes(
    patientStatus,
  );
  if (!record) return null;

  return (
    <PatientLayout>
      {isNotEnrolled && (
        <PatientProfileSection
          title="Enrollment Information"
          description="Enrollment information about the patient"
        >
          <EnrollmentInfo />
        </PatientProfileSection>
      )}

      <PatientProfileSection
        title="Care Programs"
        description="Care programs are the virtual care services that are provided to the patient."
        action={<ServiceInstanceCreateDialog />}
      >
        <ServiceInstanceDataGrid />
      </PatientProfileSection>

      <PatientProfileSection
        title="Devices"
        description="Devices that have been assigned to the patient."
      >
        <DevicesDataGrid />
      </PatientProfileSection>

      <PatientProfileSection
        title="Device History"
        description="Device history & events for the patient."
      >
        <DevicePatientActivities />
      </PatientProfileSection>

      <PatientProfileSection
        title="Care Team"
        description="Care team members are clinicians responsible for monitoring and managing the patient’s care."
        action={<CareTeamCreateButton variant="default" />}
      >
        <CareTeamList />
      </PatientProfileSection>

      <PatientProfileSection
        title="Medications"
        description="Medications that have been prescribed to the patient."
        action={<MedicationsCreateButton variant="default" />}
      >
        <MedicationsList />
      </PatientProfileSection>

      {!isNotEnrolled && (
        <PatientProfileSection
          title="Consent Form"
          description="Manage consent form for VCM services."
        >
          <ConsentForm />
        </PatientProfileSection>
      )}

      <PatientProfileSection
        title="Family & Contacts"
        description="Store patient’s family and contacts information here for easy access."
        action={<PatientContactCreateButton />}
      >
        <PatientContactsGrid />
      </PatientProfileSection>

      <PatientProfileSection
        title="Conditions & Diagnosis"
        description="Conditions that the patient has been diagnosed with."
        action={<ConditionCreate />}
      >
        <ConditionsDataGrid />
      </PatientProfileSection>

      {!isNotEnrolled && (
        <PatientProfileSection
          title="Enrollment Information"
          description="Enrollment information about the patient"
        >
          <EnrollmentInfo />
        </PatientProfileSection>
      )}

      <PatientMobileAppSection />
    </PatientLayout>
  );
};

export const PatientShowSimple: FC = (props) => {
  return (
    <ShowBase
      {...props}
      queryOptions={{
        meta: {
          expand: ['profile'],
        },
      }}
    >
      <PatientStatusProvider>
        <PatientShowSimpleView />
      </PatientStatusProvider>
    </ShowBase>
  );
};
